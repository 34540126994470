body {
  width: 100dvw;
  height: 100dvh;

  font-family: sans-serif;

  overflow: auto;

  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  width: 100%;
  height: 100%;

  @media screen and (orientation: portrait) and (max-device-width: 1024px) {
    min-width: 360px;
  }
}
